import { GridRowSelectionModel } from "@mui/x-data-grid-premium";
import React, { PropsWithChildren, useContext, useState } from "react";
import { DescriptiveDateRange } from "../../shared/utilities/dateFilterHelper";
import { defined } from "../../shared/utilities/typeHelper";
import { ITag } from "../store/state/messages/types";

export interface InboxCriteria {
  page: number;
  size: number;
  investors?: string[];
  funds?: string[];
  entities?: ITag[];
  categories?: string[];
  from?: string;
  to?: string;
  query?: string;
  descriptiveDate?: DescriptiveDateRange;
  isImportant?: boolean;
  preload: boolean;
  selectedInvestorTag?: string;
}

export interface InboxUiState {
  total?: number;
  messagesLoading: boolean;
  messageDetailLoading: boolean;
  messageDetailLoadingError?: boolean;
}

export interface InboxState {
  messageId?: string;
  setMessageId: (id: string) => void;
  criteria: InboxCriteria;
  ui: InboxUiState;
  updateCriteria: (value: Partial<InboxCriteria>) => void;
  updateCriteriaPreload: (value: Partial<InboxCriteria>) => void;
  updateUi: (value: Partial<InboxUiState>) => void;
}

export interface InboxStateContextProps extends InboxState {
  selection: GridRowSelectionModel;
  setSelection: (values: GridRowSelectionModel) => void;
}

const InboxStateContext = React.createContext<InboxStateContextProps | undefined>(undefined);

interface Props {
  value: InboxState;
}

export const InboxStateContextProvider = ({ value, children }: PropsWithChildren<Props>) => {
  const [selection, setSelection] = useState<GridRowSelectionModel>([]);
  return (
    <InboxStateContext.Provider value={{ ...value, selection, setSelection }}>{children}</InboxStateContext.Provider>
  );
};

export const useInboxStateContext = () => {
  const value = useContext(InboxStateContext);
  return defined(value);
};
