import { parseISO } from "date-fns";
import { dateMatchesDescriptiveDateRange, DescriptiveDateRange } from "../../../shared/utilities/dateFilterHelper";
import { DataCollectionSubmissionInfo } from "../../api/types/dataCollectionTypes";

export interface DataSubmissionsFilterState {
  searchValue: string;
  selectedTemplateNames: string[];
  reportingDateRange: DescriptiveDateRange | undefined;
}

export const getInitialDataSubmissionsFilterState = (): DataSubmissionsFilterState => ({
  searchValue: "",
  selectedTemplateNames: [],
  reportingDateRange: undefined,
});

const rowMatchesSearchFilter = (row: DataCollectionSubmissionInfo, searchValue: string) => {
  const searchTerm = searchValue.toLowerCase().trim();
  if (!searchTerm) {
    return true;
  }

  return Boolean(
    row.recipientName?.toLowerCase().includes(searchTerm) ||
      row.requestTemplateName.toLowerCase().includes(searchTerm) ||
      row.requestName.toLowerCase().includes(searchTerm) ||
      row.submittedBy?.toLowerCase().includes(searchTerm)
  );
};

const rowMatchesTemplateFilter = (row: DataCollectionSubmissionInfo, selectedTemplateNames: string[]) =>
  selectedTemplateNames.length === 0 || selectedTemplateNames.includes(row.requestTemplateName);

const rowMatchesReportingDateRangeFilter = (
  row: DataCollectionSubmissionInfo,
  reportingDateRange: DescriptiveDateRange | undefined
) =>
  reportingDateRange === undefined || dateMatchesDescriptiveDateRange(parseISO(row.reportingDate), reportingDateRange);

export const applyFilters = (rows: DataCollectionSubmissionInfo[], filterState: DataSubmissionsFilterState) => {
  return rows.filter(
    (row) =>
      rowMatchesSearchFilter(row, filterState.searchValue) &&
      rowMatchesTemplateFilter(row, filterState.selectedTemplateNames) &&
      rowMatchesReportingDateRangeFilter(row, filterState.reportingDateRange)
  );
};
