import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Container, Stack, Tab, Typography } from "@mui/material";
import { useState } from "react";
import ActiveDataSubmissionsList from "./ActiveDataSubmissionsList";
import CompletedDataSubmissionsList from "./CompletedDataSubmissionsList";

export const tabValues = ["active", "completed"] as const;
export type TabValue = (typeof tabValues)[number];

const DataCollectionPage = () => {
  const [selectedTab, setSelectedTab] = useState<TabValue>("active");

  return (
    <Container maxWidth={false} sx={{ height: "100%" }}>
      <Stack spacing={2} pt={2} pb={1} px={2} height="100%">
        <Typography variant="h4">Data Collection</Typography>

        <TabContext value={selectedTab}>
          <TabList onChange={(_, selectedTab) => setSelectedTab(selectedTab)}>
            <Tab value="active" label="Active" />
            <Tab value="completed" label="Completed" />
          </TabList>

          <TabPanel value="active" sx={{ height: "100%", pt: 0.5 }}>
            <ActiveDataSubmissionsList />
          </TabPanel>
          <TabPanel value="completed" sx={{ height: "100%", pt: 0.5 }}>
            <CompletedDataSubmissionsList />
          </TabPanel>
        </TabContext>
      </Stack>
    </Container>
  );
};

export default DataCollectionPage;
